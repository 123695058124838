<template>
  <div class="menu-main">
    <div class="main-title">
      <div class="main-label">菜单管理</div>
      <div>
        <el-button v-if="judgingAPermission(['menu.store'])" size="mini" type="primary" icon="el-icon-plus" @click="dialog = !dialog">新增</el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      :data="menuData"
      class="menu-table"
      :header-cell-style="{ background: '#fafafa' }"
      row-key="id"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="菜单名称">
        <template #default="{ row }">
          <span>
            <i :class="['iconfont', row.icon]" class="icon-color" />
            {{ row.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="code"
        label="菜单编码"
      ></el-table-column>
      <el-table-column label="排序" width="70">
        <template #default="{ row }">
          <el-input v-model="row.sort" disabled></el-input>
        </template>
      </el-table-column>
      <el-table-column label="菜单类型" width="150">
        <template #default="{ row }">
          <el-tag
            class="tag-color"
            :style="{ background: typeColor(row.type) }"
            >{{ typeLabel(row.type) }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="可见" width="70">
        <template #default="{ row }">
          <span>{{ row.is_show ? '显示' : '隐藏' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="permission_code"
        label="权限标识"
        v-if="isSuper"
      ></el-table-column>
      <el-table-column prop="route" label="组件路由" v-if="isSuper"></el-table-column>
      <el-table-column label="状态" width="120">
        <template>
          <!-- 暂时不用状态 后端容易出问题 先写死 -->
          <!-- <div>
            <span class="table-state" :style="{backgroundColor: row.status?'#1890ff':'#f11111',color: row.status?'#1890ff':'#f11111'}">
            </span>
            <span>{{row.status?'正常':'停用'}}
            </span>
          </div> -->
          <div>
            <span class="table-state"> </span>
            <span>正常 </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px">
        <template #default="{ row }">
          <el-button v-if="judgingAPermission(['menu.update'])" size="mini" type="text" @click="onEdit(row)">修改</el-button>
          <el-button
          
            class="table-add-btn"
            v-if="row.type !== '3' && judgingAPermission(['menu.store'])"
            size="mini"
            type="text"
            @click="onAdd(row)"
            >添加子菜单</el-button
          >
          <el-popconfirm
            v-if="judgingAPermission(['menu.delete'])"
            class="delete-btn"
            title="确定要删除这个菜单吗？"
            @confirm="onDelete(row)"
          >
            <el-button size="mini" type="text" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 编辑抽屉 -->
    <EditDrawer
      :drawer="drawer"
      :editData="editData"
      @drawerClose="drawerClose"
      @drawerSave="drawerSave"
    >
    </EditDrawer>
    <!-- 新增弹窗 -->
    <AddDialog
      :dialog="dialog"
      :parentParam="parentData"
      @dialogClose="dialogClose"
      @dialogSave="dialogSave"
    >
    </AddDialog>
  </div>
</template>

<script>
import { getMenu, detMenu } from '@/api/saasManage'
import { Tag, Popconfirm } from 'element-ui'
import { judgingAPermission } from '@/utils/tools'
import EditDrawer from './EditDrawer.vue'
import AddDialog from '@/manage-views/views/sysSetting/menu/components/AddDialog.vue'
export default {
  components: {
    'el-tag': Tag,
    EditDrawer,
    AddDialog,
    'el-popconfirm': Popconfirm,
  },
  props: {},
  data() {
    return {
      judgingAPermission: judgingAPermission,
      loading: false,
      menuData: [],
      drawer: false,
      dialog: false,
      editData: {},
      parentData: {},
      search: '',
      isSuper:false,
    }
  },
  mounted() {
    this.getData()
    // 判断当前用户是不是超级管理员
    this.isSuper = Boolean(JSON.parse(localStorage.getItem('userInfo')).saas_admin) 
  },
  methods: {
    /**
     * @desc:筛选
     */
    onSearch(data) {
      this.search = data
      this.getData()
    },
    onRefresh() {
      this.search = ''
      this.getData()
    },
    /**
     * @desc:关闭新增弹窗
     */
    dialogClose() {
      this.dialog = !this.dialog
    },
    dialogSave() {
      this.dialog = false
      this.getData()
    },
    drawerSave() {
      this.drawer = !this.drawer
      this.getData()
    },
    /**
     * @desc:关闭编辑抽屉
     */
    drawerClose() {
      this.drawer = !this.drawer
    },
    /**
     * @desc:打开编辑弹窗
     */
    onEdit(row) {
      this.editData = row
      this.drawer = !this.drawer
    },
    onAdd(row) {
      const { name, id, type } = row
      this.parentData = {
        parent_name: name,
        parent_id: id,
        parent_type: type,
      }
      this.dialog = !this.dialog
    },
    onDelete(row) {
      detMenu({ id: [row.id] })
        .then((res) => {
          if (res.data && res.data.code !== 200) {
            return false
          }
          this.getData()
        })
        .catch(() => {
          this.$message.error(`删除失败`)
        })
    },
    // 不同菜单类型用不同的颜色已经label
    typeColor(type) {
      if (type === '1') {
        return '#2db7f5'
      } else if (type === '2') {
        return '#87d068'
      } else if (type === '3') {
        return '#108ee9'
      } else if (type === '4') {
        return '#409EFF'
      } else if (type === '5') {
        return '#67C23A'
      } else if (type === '6') {
        return '#909399'
      }
    },
    typeLabel(type) {
      if (type === '1') {
        return '菜单组'
      } else if (type === '2') {
        return '菜单'
      } else if (type === '3') {
        return '按钮'
      } else if (type === '4') {
        return '应用'
      } else if (type === '5') {
        return '插件'
      } else if (type === '6') {
        return '服务'
      }
    },
    /**
     * @desc: 得到菜单管理列表
     */
    getData() {
      this.loading = true
      getMenu({ name: this.search })
        .then((res) => {
          if (res.data && res.data.code !== 200) {
            return false
          }
          this.menuData = res.data.data.data
          this.loading = false
        })
        .catch(() => {
          this.$message.error(`获取数据失败`)
        })
    },
  },
}
</script>
<style lang="less">
@import "../../../../css/manageMain.less";
.menu-main {
  background-color: #fff;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  .menu-table {
    width: 100%;
    flex: 1;
    padding: 0 15px;
    overflow: auto;
  }
}
</style>
