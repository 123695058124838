<template>
  <div class="menu-dialog">
    <el-dialog
      title="添加菜单"
      :fullscreen="dialogFull"
      :visible.sync="dialogs"
      width="800px"
      :before-close="onClose"
    >
      <template slot="title">
        <div class="avue-crud__dialog__header">
          <span> 添加菜单 </span>
          <div
            class="avue-crud__dialog__menu"
            @click="dialogFull ? (dialogFull = false) : (dialogFull = true)"
          >
            <i class="el-icon-full-screen"></i>
          </div>
        </div>
      </template>
      <el-form
        label-width="80px"
        :model="form"
        label-position="top"
        class="add-form"
        :rules="rules"
        ref="dialog"
      >
        <el-form-item class="form-item-height" label="应用图标" prop="icon">
          <el-select
            :value="other"
            style="width: 344px"
            placeholder=""
            class="select-icon"
          >
            <template slot="prefix">
              <div class="header-img">
                <i
                  :style="'color: ' + form.color"
                  :class="
                    form.icon
                      ? 'iconfont ' + form.icon
                      : 'iconfont icon-a-1-1zhuye'
                  "
                ></i>
              </div>
            </template>
            <template #empty>
              <div
                class="choose-color"
                style="width: 344px; padding: 8px; height: 384px"
              >
                <p>选择类型</p>
                <div class="color-container">
                  <div
                    class="color-item"
                    style="
                      background: #f2f4f7;
                      border-radius: 4px;
                      width: 40px;
                      height: 32px;
                    "
                    :style="
                      currentChooseType.font_class === item.font_class
                        ? 'border: 2px solid #D0E2FF;background: #fff'
                        : ''
                    "
                    v-for="(item, index) in iconList"
                    :key="index"
                    @click="currentChooseType = item"
                  >
                    <i
                      :style="'color: ' + item.color"
                      class="iconfont"
                      :class="item.font_class"
                    ></i>
                  </div>
                </div>
                <p>选择图形</p>
                <div class="color-container" style="flex: 1; overflow-y: auto">
                  <div
                    class="color-item"
                    v-for="(icon, index) in currentChooseType.children"
                    :key="index"
                    :style="
                      form.icon === icon.font_class
                        ? 'background: #337EFF;border: none;color:#fff'
                        : ''
                    "
                    @click="
                      ;(form.icon = icon.font_class),
                        (form.color = currentChooseType.color)
                    "
                  >
                    <i
                      class="iconfont"
                      :style="form.icon === icon.font_class ? 'color:#fff' : ''"
                      :class="icon.font_class"
                    ></i>
                  </div>
                </div>
              </div>
            </template>
          </el-select>
        </el-form-item>

        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="菜单类型" prop="type">
              <el-select
                class="form-input"
                v-model="form.type"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in typeData"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上级菜单">
              <el-select
                v-model="form.parent_name"
                placeholder="请选择上级菜单"
                class="form-input"
              >
                <el-option label="区域一" value="0" class="form-option">
                  <el-tree
                    ref="tree"
                    :data="parentData"
                    default-expand-all
                    node-key="id"
                    @node-click="handleNodeClick"
                    :props="defaultProps"
                  />
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="菜单编码" prop="code">
              <el-input class="form-input" v-model="form.code"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单名称" prop="name">
              <el-input class="form-input" v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="排序" prop="sort">
              <el-input class="form-input" v-model="form.sort"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="权限标识" v-if="isSuper">
              <el-input
                class="form-input"
                v-model="form.permission_code"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="form.type !== '3'" :gutter="15">
          <el-col :span="12">
            <el-form-item label="是否显示">
              <el-radio-group v-model="form.is_show">
                <el-radio-button label="1">显示</el-radio-button>
                <el-radio-button label="0">隐藏</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col v-if="form.type === '2' && isSuper" :span="12">
            <el-form-item label="路由地址" prop="route">
              <el-input class="form-input" v-model="form.route"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="备注">
          <el-input
            class="form-input"
            type="textarea"
            v-model="form.remarks"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="mini" @click="onClose">取消</el-button>
        <el-button size="mini" type="primary" @click="onSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { menuIcons } from './icon'
import { Tree, RadioButton } from 'element-ui'
import { addMenu, getMenuSelect } from '@/api/saasManage'
import { dictSelect } from '@/api/saas'
export default {
  name: 'AddDialog',
  components: {
    'el-tree': Tree,
    'el-radio-button': RadioButton,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    parentParam: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      other: '',
      iconList: menuIcons,
      currentChooseType: menuIcons[0],
      typeData: null,
      parentData: [
        {
          name: '顶级菜单',
          id: 0,
          children: [],
        },
      ],
      dialogs: false,
      dialogFull: false,
      form: {
        parent_name: '', //父级名称
        path: '', //组件路径
        name: '', //菜单名称
        is_external: 0, //是否外部链接
        remarks: '', // 备注
        type: '',
        status: 1, //状态（0：停用 1:正常 ）
        route: '', // 路由地址
        permission_code: '', //权限标识
        is_show: 1, //是否显示（1：显示，0：隐藏）
        sort: '', // 排序
        code: '', // 编码
        icon: '', //图标
        parent_id: '', //父级id
      },
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      rules: {
        type: [
          { required: true, message: '菜单类型不能为空', trigger: 'blur' },
        ],
        code: [
          { required: true, message: '菜单编码不能为空', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '菜单名称不能为空', trigger: 'blur' },
        ],
        route: [
          { required: true, message: '路由地址不能为空', trigger: 'blur' },
        ],
      },
      isSuper: false,
    }
  },
  mounted() {
    this.isSuper = JSON.parse(localStorage.getItem('userInfo')).saas_admin
  },
  watch: {
    dialog(newVal) {
      this.dialogs = newVal
      if (newVal) {
        this.getParentData()
      }
    },
    parentParam(newVal) {
      this.form.parent_id = newVal.parent_id
      this.form.parent_name = newVal.parent_name
      this.form.parent_type = newVal.parent_type
    },
  },
  methods: {
    /**
     * @desc 保存按钮
     */
    onSave() {
      this.$refs['dialog'].validate((valid) => {
        if (valid) {
          addMenu(this.form)
            .then((res) => {
              if (res.data && res.data.code !== 200) {
                return false
              }
              this.$emit('dialogSave')
              this.form = {
                parent_name: '', //父级名称
                path: '', //组件路径
                name: '', //菜单名称
                is_external: 0, //是否外部链接
                remarks: '', // 备注
                type: '',
                status: 1, //状态（0：停用 1:正常 ）
                route: '', // 路由地址
                permission_code: '', //权限标识
                is_show: 1, //是否显示（1：显示，0：隐藏）
                sort: '', // 排序
                code: '', // 编码
                icon: '', //图标
                parent_id: 0, //父级id
              }
            })
            .catch(() => {
              this.$message.error(`保存失败`)
            })
        } else {
          return false
        }
      })
    },
    /**
     * @desc:关闭按钮
     */
    onClose() {
      this.dialogs = false
      this.form = {
        parent_name: '', //父级名称
        path: '', //组件路径
        name: '', //菜单名称
        is_external: 0, //是否外部链接
        remarks: '', // 备注
        type: '',
        status: 1, //状态（0：停用 1:正常 ）
        route: '', // 路由地址
        permission_code: '', //权限标识
        is_show: 1, //是否显示（1：显示，0：隐藏）
        sort: '', // 排序
        code: '', // 编码
        icon: '', //图标
        parent_id: '', //父级id
      }
      this.$emit('dialogClose')
    },
    /**
     * @desc:获取上级菜单下拉选择框的数据
     * @param
     */
    getParentData() {
      getMenuSelect(0)
        .then((res) => {
          if (res.data && res.data.code !== 200) {
            return false
          }
          this.parentData[0].children = res.data.data
        })
        .catch(() => {
          this.$message.error(`获取菜单管理部门数据失败`)
        })
      //类型下拉框的数据
      let typeParam = {
        type: 'menu',
      }
      dictSelect(typeParam)
        .then((res) => {
          if (res.data && res.data.code !== 200) {
            return false
          }
          if (this.form.parent_type == 2) {
            res?.data?.data[0]?.children.forEach((r) => {
              if (r.name == '按钮') {
                this.typeData = [r]
                this.form.type = r.value
              }
            })
          } else {
            this.typeData = res?.data?.data[0]?.children
          }
        })
        .catch(() => {
          this.$message.error(`获取下拉选择类型错误`)
        })
    },
    /**
     * @desc: 下拉树选择的数据
     */
    handleNodeClick(row) {
      const { id, name } = row
      this.form.parent_id = id
      this.form.parent_name = name
    },
  },
}
</script>
<style lang="less" scoped>
.choose-color {
  width: 750px;
  height: 300px;
  overflow: auto;
  padding: 10px 0 10px 10px;
  box-sizing: border-box;
  margin: 0;
  .icon-select {
    display: inline-block;
    width: 40px;
    padding: 5px;
    > i {
      font-size: 20px;
    }
  }
}
.menu-dialog {
  .add-form {
    height: 450px;
    overflow: auto;
  }
  .form-option {
    height: auto;
    background-color: #fff !important;
  }
}
.choose-color {
  width: 460px;
  height: 300px;
  display: flex;
  flex-direction: column;
  padding: 10px 0 10px 10px;
  box-sizing: border-box;
  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #121315;
    margin: 8px 0;
  }
  .color-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    .color-item {
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 12px 12px 0;
      box-sizing: border-box;
      width: 40px;
      height: 40px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #f2f4f7;
      .color-item-i {
        color: #606266;
      }
    }
  }
}
/* 头像显示 */
.header-img {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 4px;
  border: 2px solid #d0e2ff;
  i {
    font-size: 26px;
  }
}
:deep(.select-icon) {
  .el-input {
    width: 48px;
    height: 48px;
    background: #ffffff;
    border-radius: 4px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
    }
    .el-input__suffix {
      display: none;
    }
    .el-input__prefix {
      width: 48px;
      height: 48px;
      i {
        font-size: 18px;
        color: #000;
      }
    }
  }
}
</style>

<style lang="less" scoped>
@import '../../../../css/manageAdd.less';
</style>
