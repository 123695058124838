<template>
	<div class="menu-edit-main">
		<el-drawer
			title="修改菜单"
			:visible.sync="drawers"
			:before-close="handleClose">
			<div class="form-class">
				<el-form
					ref="form"
					:model="form"
					label-position="top"
					:rules="rules">
					<el-form-item
						class="form-item-height"
						label="应用图标"
						prop="icon">
						<el-select
							:value="other"
							style="width: 344px"
							placeholder=""
							class="select-icon">
							<template slot="prefix">
								<div class="header-img">
									<i
										:style="'color: ' + form.color"
										:class="form.icon ? 'iconfont ' + form.icon : 'iconfont icon-a-1-1zhuye'"></i>
								</div>
							</template>
							<template #empty>
								<div
									class="choose-color"
									style="width: 344px; padding: 8px; height: 384px">
									<p>选择类型</p>
									<div class="color-container">
										<div
											class="color-item"
											style="background: #f2f4f7; border-radius: 4px; width: 40px; height: 32px"
											:style="
												currentChooseType.font_class === item.font_class
													? 'border: 2px solid #D0E2FF;background: #fff'
													: ''
											"
											v-for="(item, index) in iconList"
											:key="index"
											@click="currentChooseType = item">
											<i
												:style="'color: ' + item.color"
												class="iconfont"
												:class="item.font_class"></i>
										</div>
									</div>
									<p>选择图形</p>
									<div
										class="color-container"
										style="flex: 1; overflow-y: auto">
										<div
											class="color-item"
											v-for="(icon, index) in currentChooseType.children"
											:key="index"
											:style="form.icon === icon.font_class ? 'background: #337EFF;border: none;color:#fff' : ''"
											@click="(form.icon = icon.font_class), (form.color = currentChooseType.color);">
											<i
												class="iconfont"
												:style="form.icon === icon.font_class ? 'color:#fff' : ''"
												:class="icon.font_class"></i>
										</div>
									</div>
								</div>
							</template>
						</el-select>
					</el-form-item>
					<el-form-item label="上级菜单">
						<el-select
							v-model="form.parent_name"
							placeholder="请选择上级菜单"
							class="form-select">
							<el-option
								label="区域一"
								value="0"
								class="form-option">
								<el-tree
									ref="tree"
									:data="parentData"
									default-expand-all
									node-key="id"
									@node-click="handleNodeClick"
									:props="defaultProps" />
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="菜单类型">
						<el-select
							:disabled="!isSuper"
							class="form-half-input"
							v-model="form.type"
							placeholder="请选择">
							<el-option
								v-for="item in typeData"
								:key="item.value"
								:label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item
						label="菜单编码"
						prop="code">
						<el-input v-model="form.code"></el-input>
					</el-form-item>
					<el-form-item
						label="菜单名称"
						prop="name">
						<el-input v-model="form.name"></el-input>
					</el-form-item>
					<el-form-item
						label="排序"
						prop="sort">
						<el-input v-model="form.sort"></el-input>
					</el-form-item>
					<!-- 暂时不用状态功能 后端容易出问题 先写死 -->
					<!-- <el-form-item label="状态" v-if="form.type!=='3'">
            <el-radio-group v-model="form.status">
              <el-radio-button label="1">正常</el-radio-button>
              <el-radio-button label="0">停用</el-radio-button>
            </el-radio-group>
          </el-form-item> -->
					<el-form-item
						label="路由地址"
						prop="route"
						v-if="isSuper">
						<el-input v-model="form.route"></el-input>
					</el-form-item>
					<el-form-item
						label="权限标识"
						v-if="isSuper">
						<el-input v-model="form.permission_code"></el-input>
					</el-form-item>
					<el-form-item
						label="是否显示"
						v-if="form.type !== '3'">
						<el-radio-group v-model="form.is_show">
							<el-radio-button label="1">显示</el-radio-button>
							<el-radio-button label="0">隐藏</el-radio-button>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="备注">
						<el-input
							type="textarea"
							v-model="form.remarks"></el-input>
					</el-form-item>
					<el-form-item class="bottom-btn">
						<el-button
							size="mini"
							@click="handleClose"
							>取消</el-button
						>
						<el-button
							size="mini"
							type="primary"
							@click="save"
							>保存</el-button
						>
					</el-form-item>
				</el-form>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import { Tree, RadioGroup, RadioButton } from 'element-ui';
import { editMenu, getMenuSelect } from '@/api/saasManage';
import { dictSelect } from '@/api/saas';
import { menuIcons } from './icon.js';
import { deepClone } from '@topology/core';
export default {
	name: 'EditDrawer',
	components: {
		'el-radio-group': RadioGroup,
		'el-radio-button': RadioButton,
		'el-tree': Tree
	},
	props: {
		drawer: {
			type: Boolean,
			default: false
		},
		editData: {
			type: Object,
			default: () => {}
		}
	},

	data() {
		return {
			other: '',
			iconList: menuIcons,
			currentChooseType: menuIcons[0],
			typeData: null,
			drawers: false,
			form: {},
			parentData: [
				{
					name: '顶级菜单',
					id: 0,
					children: []
				}
			],
			defaultProps: {
				children: 'children',
				label: 'name'
			},
			rules: {
				code: [{ required: true, message: '菜单编码不能为空', trigger: 'blur' }],
				name: [{ required: true, message: '菜单名称不能为空', trigger: 'blur' }],
				route: [{ required: true, message: '路由地址不能为空', trigger: 'blur' }]
			},
			isSuper: false
		};
	},
	watch: {
		drawer(newVal) {
			this.drawers = newVal;
		},
		editData(newVal) {
			if (newVal && newVal.id) {
				this.form = deepClone(newVal);
			}
		}
	},
	mounted() {
		this.getParentData();
		this.isSuper = Boolean(JSON.parse(localStorage.getItem('userInfo')).saas_admin);
	},
	methods: {
		save() {
			this.$refs['form'].validate((valid) => {
				if (!valid) {
					return false;
				}
				editMenu(this.form, this.form.id)
					.then((res) => {
						if (res.data && res.data.code !== 200) {
							return false;
						}
						this.$emit('drawerSave');
					})
					.catch(() => {
						this.$message.error(`保存失败`);
					});
			});
		},
		/**
		 * @desc:关闭
		 */
		handleClose() {
			this.drawers = false;
			this.$emit('drawerClose');
		},
		/**
		 * @desc: 树点击
		 */
		handleNodeClick(row) {
			this.form.parent_id = row.id;
			this.form.parent_name = row.name;
		},
		getParentData() {
			getMenuSelect(0)
				.then((res) => {
					if (res.data && res.data.code !== 200) {
						return false;
					}
					this.parentData[0].children = res.data.data;
				})
				.catch(() => {
					this.$message.error(`获取菜单管理部门数据失败`);
				});
			//类型下拉框的数据
			let typeParam = {
				type: 'menu'
			};
			dictSelect(typeParam)
				.then((res) => {
					if (res.data && res.data.code !== 200) {
						return false;
					}
					if (this.form.parent_type == 2) {
						res?.data?.data[0]?.children.forEach((r) => {
							if (r.name == '按钮') {
								this.typeData = [r];
								this.form.type = r.value;
							}
						});
					} else {
						this.typeData = res?.data?.data[0]?.children;
					}
				})
				.catch(() => {
					this.$message.error(`获取下拉选择类型错误`);
				});
		}
	}
};
</script>
<style lang="less" scoped>
.menu-edit-main {
	color: #444;
	text-align: left;
	.form-class {
		padding: 0px 24px 50px 24px;
		font-size: 14px;
		line-height: 1.5;

		.form-select {
			width: 100%;
		}
		.icon-color {
			color: #2f54eb;
		}
	}
	.el-form-item__label {
		padding: 0 0 8px !important;
		width: 100% !important;
		text-align: left;
	}
	.bottom-btn {
		margin: 0;
		position: absolute;
		bottom: 0;
		width: 100%;
		border-top: 1px solid #e8e8e8;
		padding: 10px 16px;
		text-align: right;
		right: 0;
		background: #fff;
		border-radius: 0 0 4px 4px;
		display: flex;
		justify-content: flex-end;
		box-sizing: border-box;
	}
}
.choose-color {
	width: 521px;
	height: 300px;
	overflow: auto;
	padding: 10px 0 10px 10px;
	box-sizing: border-box;
	margin: 0;
	.icon-select {
		display: inline-block;
		width: 40px;
		padding: 5px;
		> i {
			font-size: 20px;
		}
	}
}
.form-option {
	height: auto;
	background-color: #fff;
}
.choose-color {
	width: 460px;
	height: 300px;
	display: flex;
	flex-direction: column;
	padding: 10px 0 10px 10px;
	box-sizing: border-box;
	p {
		font-size: 14px;
		font-weight: 600;
		line-height: 22px;
		color: #121315;
		margin: 8px 0;
	}
	.color-container {
		width: 100%;
		height: auto;
		display: flex;
		flex-wrap: wrap;
		.color-item {
			overflow: hidden;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 12px 12px 0;
			box-sizing: border-box;
			width: 40px;
			height: 40px;
			background: #ffffff;
			border-radius: 4px;
			border: 1px solid #f2f4f7;
			.color-item-i {
				color: #606266;
			}
		}
	}
}
/* 头像显示 */
.header-img {
	width: 48px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #ffffff;
	border-radius: 4px;
	border: 2px solid #d0e2ff;
	i {
		font-size: 26px;
	}
}
:deep(.select-icon) {
	.el-input {
		width: 48px;
		height: 48px;
		background: #ffffff;
		border-radius: 4px;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;

		input {
			display: none;
		}
		.el-input__suffix {
			display: none;
		}
		.el-input__prefix {
			width: 48px;
			height: 48px;
			i {
				font-size: 18px;
				color: #000;
			}
		}
	}
}
</style>
